import Toast from "vue-toastification";

const options = {
  position: "bottom-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.use(Toast, options);
});
