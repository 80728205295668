import validate from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/pages/runtime/validate.js";
import referral_45global from "/vercel/path0/packages/ddc-giving-circle/middleware/referral.global.ts";
import manifest_45route_45rule from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  referral_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "authenticated-donor": () => import("/vercel/path0/packages/ddc-giving-circle/middleware/authenticated-donor.ts"),
  "require-staff": () => import("/vercel/path0/packages/ddc-giving-circle/middleware/require-staff.ts")
}