import { useReferralCode } from "~/domains/auth/composables";

export default defineNuxtRouteMiddleware(async (to) => {
  const referralCode = useReferralCode();

  const referral = to.query.referral;
  if (!referral || typeof referral !== "string") {
    return;
  }

  referralCode.value = referral;
});
