import routerOptions0 from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/packages/ddc-giving-circle/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}