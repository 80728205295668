import { defineStore } from "pinia";

export const useStaffStore = defineStore("StaffStore", {
  state: (): SessionState => ({
    info: null,
    loaded: false,
    isLoading: false,
  }),

  getters: {
    staffToken(state): string | null {
      return state.info?.session?.id || null;
    },
    hasLinkedStripeAccount(state): boolean {
      return !!state.info?.organization?.stripeAccountId;
    },
    organizationId(state): number | null {
      return state.info?.organization?.id || null;
    },
    organizationCreatedAt(state): string {
      return state.info?.organization?.createdAt || "";
    },
    organizationPageUrl(state): string {
      return `https://${state.info?.organization.defaultUrl.url}.${
        useRuntimeConfig().public.baseDomain
      }`;
    },
  },

  actions: {
    saveState(info: SessionInfo): void {
      const staffTokenCookie = useCookie("circleStaffToken", {
        maxAge: 60 * 60 * 24 * 7,
      });

      if (info?.session?.id) {
        staffTokenCookie.value = info.session.id;
        this.info = info;
        this.loaded = true;
      }
    },

    async loadState(): Promise<void> {
      if (this.loaded) {
        return;
      }

      const staffToken = useCookie("circleStaffToken")?.value;

      if (staffToken) {
        const res = await $circleFetch<SessionInfo>("auth/user", {
          method: "GET",
          headers: {
            Authorization: staffToken,
          },
        });

        this.loaded = true;
        this.info = res;
      }
    },

    async signIn(email: string, password: string): Promise<boolean> {
      try {
        const res = await $circleFetch<SessionInfo>("auth/login", {
          method: "POST",
          body: { email, password },
        });

        this.saveState(res);

        return true;
      } catch (err: any) {
        const error = err?.response?._data ? err?.response?._data : err;
        throw error;
      }
    },

    async signOut() {
      await $circleFetch("auth/logout", {
        method: "POST",
      });
      useCookie("circleStaffToken").value = null;
      this.info = null;

      navigateTo("/");
    },

    async signUp(body: ISignUpParam): Promise<string | boolean> {
      try {
        const res = await $circleFetch<SessionInfo>("auth/signup", {
          method: "POST",
          body,
        });

        this.saveState(res);

        return true;
      } catch (err: any) {
        const error = err?.response?._data ? err?.response?._data : err;
        throw error;
      }
    },

    async setSelectedCampaigns(campaignIds: number[]): Promise<void> {
      try {
        await $circleFetch<number[]>("organization/selectCampaigns", {
          method: "PATCH",
          body: { campaigns: campaignIds },
        });

        if (this.info) {
          this.info.organization.ddcCampaigns = campaignIds;
        }

        useToast().success("Campaigns saved successfully");
      } catch (e) {
        console.error(e);
        useToast().error("Something went wrong");
      }
    },
  },
});
