import posthog from "posthog-js";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (config.public.environment === "production") {
    const client = posthog.init(config.public.posthog.publicKey, {
      api_host: config.public.posthog.apiHost,
      person_profiles: "identified_only",
      capture_pageview: false,
    });

    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture(
          "$pageview",
          {
            current_url: to.fullPath,
          },
          { transport: "sendBeacon" }
        );
      });
    });

    return {
      provide: {
        posthog: client,
      },
    };
  }
});
