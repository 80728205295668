export const NEW_CIRCLES = [
  "selenagomez",
  "alok",
  "leehutchinson",
  "moseswest",
  "peterbond",
];

export function useIsNewCircle(slug: MaybeRefOrGetter<string>) {
  return computed(() => NEW_CIRCLES.includes(toValue(slug)));
}
