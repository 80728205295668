import { useStaffStore } from "~~/stores/Staff/StaffStore";
import snakecaseKeys from "snakecase-keys";
import camelize from "camelize-ts";
import { useDonorAccessToken } from "~/domains/auth/composables";

export const useCircleFetch = async (path: string) => {
  const runtimeConfig = useRuntimeConfig();

  return await useFetch(() => `${runtimeConfig.public.apiBase}${path}`);
};

export const $circleFetch = async <T>(
  path: string,
  options?: {
    method: any;
    body?: any;
    headers?: any;
  }
) => {
  if (!options) {
    options = {
      method: "GET",
    };
  }

  if (options.body && !(options.body instanceof FormData)) {
    options.body = snakecaseKeys(options.body, { deep: true });
    // console.log("camel", options.body);
  }

  if (useStaffStore().staffToken) {
    if (!options.headers) options.headers = {};

    options.headers = {
      ...options.headers,
      Authorization: useStaffStore().staffToken,
      Accepts: "application/json",
    };
  } else if (useDonorAccessToken().value) {
    if (!options.headers) options.headers = {};

    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${useDonorAccessToken().value}`,
      Accepts: "application/json",
    };
  }

  const res = await $fetch<T>(path, {
    baseURL: useRuntimeConfig().public.apiBase,
    ...options,
  });

  return camelize<T>(res);
};
