export function useDonorAccessToken() {
  return useCookie("donor-access-token", {
    domain: useRuntimeConfig().public.baseDomain.replace(/:\d+$/, ""),
    sameSite: "lax",
    secure: import.meta.env.PROD,
    path: "/",
  });
}

export function useReferralCode() {
  return useCookie("referral-code");
}

export function useReferralLinkUrl(
  slug: MaybeRefOrGetter<string>,
  referralCode: MaybeRefOrGetter<string>
) {
  const baseDomain = useRuntimeConfig().public.baseDomain;

  return computed(
    () =>
      `https://${toValue(slug)}.${baseDomain}/?referral=${toValue(
        referralCode
      )}`
  );
}
