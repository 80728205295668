import revive_payload_client_YxFnpBcrYS from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nsYzYsP6C3 from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LYs3m6ho0s from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_BFKHwuJEET from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.11_sass@1.7_63izgbmou34djab3a6akuvsfza/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_riohZFvrcc from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PQV5d4bUt8 from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GqPZ4KDbOK from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kziZuo6q7O from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_uNikvQUQaC from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.32_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/ddc-giving-circle/.nuxt/components.plugin.mjs";
import prefetch_client_RpvUqldL1G from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v3fkozxvdlsb2sie74kiqeqaoa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_r26A1gQHgB from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._yt3e2oikutugcto5bcvhf7m4bm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/ddc-giving-circle/.nuxt/sentry-client-config.mjs";
import plugin_NgJBYVRlAM from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@playwright+test@1.45.2_@types+node@20.14.11_h3@1._dzzquvq3pnwtfyebj3bk4p53ta/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import motion_zXxgpAD4Qn from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@vueuse+motion@2.2.4_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.5.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import api_client_oAhitvIGap from "/vercel/path0/packages/ddc-giving-circle/plugins/api-client.ts";
import dayjs_pGiXRjcsJO from "/vercel/path0/packages/ddc-giving-circle/plugins/dayjs.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/packages/ddc-giving-circle/plugins/posthog.client.ts";
import toast_ysMjUcU7eJ from "/vercel/path0/packages/ddc-giving-circle/plugins/toast.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/packages/ddc-giving-circle/plugins/vue-query.ts";
export default [
  revive_payload_client_YxFnpBcrYS,
  unhead_nsYzYsP6C3,
  router_LYs3m6ho0s,
  _0_siteConfig_BFKHwuJEET,
  payload_client_riohZFvrcc,
  navigation_repaint_client_PQV5d4bUt8,
  check_outdated_build_client_GqPZ4KDbOK,
  chunk_reload_client_kziZuo6q7O,
  plugin_vue3_uNikvQUQaC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RpvUqldL1G,
  sentry_client_r26A1gQHgB,
  sentry_client_config_o34nk2sJbg,
  plugin_NgJBYVRlAM,
  motion_zXxgpAD4Qn,
  api_client_oAhitvIGap,
  dayjs_pGiXRjcsJO,
  posthog_client_fUucxKWhZE,
  toast_ysMjUcU7eJ,
  vue_query_wrmMkNpEpe
]