import type { RouterOptions } from "@nuxt/schema";

export default <RouterOptions>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp();
    const subdomain = useSubdomain();
    const contextSubdomain = ssrContext?.event.context.subdomain as
      | string
      | undefined;
    if (contextSubdomain) subdomain.value = contextSubdomain;

    if (subdomain.value) {
      const subdomainRoute = _routes.filter((i) =>
        i.path.includes("/subdomain/:slug")
      );

      const subdomainRouteMapped = subdomainRoute.map((i) => {
        return {
          ...i,
          path: i.path.includes("/subdomain/:slug()")
            ? i.path.replace("/subdomain/:slug()", "/")
            : i.path.replace("/subdomain/:slug/()", "/"),
        };
      });

      return subdomainRouteMapped;
    }
  },
};
