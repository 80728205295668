export const useSubdomain = () => useState<string>("subdomain", () => "");

export const useBaseDomainURL = (path: string) => {
  const config = useRuntimeConfig();
  const baseDomain = config.public.baseDomain;
  const pathWithoutFirstSlash = path.startsWith("/") ? path.slice(1) : path;

  return import.meta.env.PROD
    ? `https://${baseDomain}/${pathWithoutFirstSlash}`
    : config.public.devSSL
    ? `https://${baseDomain}/${pathWithoutFirstSlash}`
    : `http://${baseDomain}/${pathWithoutFirstSlash}`;
};

export function useSubdomainURL(slug: MaybeRefOrGetter<string>, path: string) {
  const subdomain = useSubdomain();
  const config = useRuntimeConfig();
  const baseDomain = config.public.baseDomain;
  const pathWithoutFirstSlash = path.startsWith("/") ? path.slice(1) : path;

  return computed(() =>
    subdomain.value
      ? path
      : import.meta.env.PROD
      ? `https://${toValue(slug)}.${baseDomain}/${pathWithoutFirstSlash}`
      : config.public.devSSL
      ? `https://${toValue(slug)}.${baseDomain}/${pathWithoutFirstSlash}`
      : `http://${toValue(slug)}.${baseDomain}/${pathWithoutFirstSlash}`
  );
}
